@media (max-aspect-ratio: 1/1) {

    #main-container {
      display: flex;
      flex-direction: column-reverse;
    }


    #cowebsite {
      left: 0;
      top: 0;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column-reverse;

      &.loading {
        transform: translateY(-90%);
      }
      &.hidden {
        transform: translateY(-100%);
      }

      main {
        height: 100%;
      }

      &-container {
        display: none;
      }


      aside {
        height: 50px;
        cursor: ns-resize;
        flex-direction: row-reverse;
        align-items: center;
        display: flex;
        justify-content: space-between;

        #cowebsite-aside-holder {
          pointer-events: none;
          height: 100%;

          img {
            height: 80%;
          }
        }

        #cowebsite-sub-icons {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 0;
          height: 100%;
          visibility: visible;

          img {
            height: 30px;
            width: 30px;
            cursor: pointer !important;
            border-radius: 50%;
            background-color: whitesmoke;
          }

          &>div {
            display: flex;
            margin-left: 2px;
            margin-right: 2px;
          }
        }

        #cowebsite-aside-buttons {
          flex-direction: row-reverse;
          margin-left: auto;
          margin-bottom: 0;
          justify-content: end;
        }

        img {
          cursor: ns-resize;
        }

        #cowebsite-fullscreen {
          padding-top: 0;
        }

        .top-right-btn {
          img {
            width: 15px;
          }
        }
      }
    }
  }

@media (max-width:960px) and (max-height:768px) {
  #cowebsite {

    &-container {
      display: none;
    }

    aside {
      #cowebsite-sub-icons {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        height: 100%;
        visibility: visible;

        img {
          height: 28px;
          width: 28px;
          cursor: pointer !important;
          border-radius: 50%;
          background-color: whitesmoke;
        }

        &>div {
          display: flex;
          margin-top: 2px;
          margin-bottom: 2px;
          transform: rotate(-90deg);
        }
      }

      img {
        cursor: ns-resize;
      }
    }
  }
}
